import config from '@configs/index'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import Log from '../../services/Log'
import { seoUrlConcat } from '../../utils/url'
import { siteSelector } from '../../redux/selectors/site'
import { useSeoComponent, SEO_PAGE_TYPES } from '@foundation/hooks/useSeoComponent'
import { HeadComponent } from './Head'
import { Category } from '@components/PagesSeo/category'
import Plp from '@views/Plp'
import NotFound from '@views/NotFound/NotFound'
import { Product } from '@components/PagesSeo/product'
import { setAlternateHrefs } from '@features/seo/slice'
import { CommerceEnvironment } from '@constants/common'
import { useGetAlternateUrlQuery } from '@features/seo/query'
import { ProductContextProvider } from '@components/PagesSeo/product/context/ProductContext'

export interface AlternateURL {
  key: string
  value: string
}

const STORE_URL_MAP = {
  '': '.ca',
  'fr-ca': '.ca',
  'en-ca': '.ca',
  'en-nz': '.co.nz',
  'en-au': '.com.au',
}

export const replaceSEODomain = (hrefLang: string, currentDomain: string) => {
  const domainRegex = /clearly(.+)$/
  const replacement = STORE_URL_MAP[hrefLang]
  return currentDomain.match(domainRegex) ? currentDomain.replace(domainRegex, `clearly${replacement}`) : currentDomain
}

const SeoPageComponent = React.memo(
  (props: any) => {
    switch (props.page.type) {
      case SEO_PAGE_TYPES.PRODUCT_LIST_PAGE:
        return <Plp {...props} />
      case SEO_PAGE_TYPES.PRODUCT_PAGE:
      case SEO_PAGE_TYPES.ITEM_PAGE:
      case SEO_PAGE_TYPES.VARIANT_PAGE:
        return <Product {...props} />
      case SEO_PAGE_TYPES.CATEGORY_PAGE:
        return <Category {...props} />
      default:
        return <NotFound {...props} />
    }
  },
  (prevProps, nextProps) => {
    return JSON.stringify(nextProps) === JSON.stringify(prevProps)
  }
)

const SEO: React.FC<any> = (props: any) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const { seoData, seoAltUrl, host } = props
  const query = router.query as { params: string[] }
  const site = useSelector(siteSelector)!
  Log.info('SEO Component - site used: ' + JSON.stringify(site))
  const currentLangCode = router.locale as string
  const url = `${currentLangCode.toLowerCase()}/${query.params.join('/')}`

  const brand = config?.storePrefix?.toUpperCase() as string
  Log.info('SEO Component - languageId: ' + site.langId)

  const { seoConfig } = useSeoComponent(seoData!, brand)

  const getAlternateUrls = (langUrlMap: Record<string, string>): Array<AlternateURL> => {
    return Object.entries(langUrlMap).map(([key, value]) => {
      const langCode = CommerceEnvironment.languageMap[key]
      return {
        key: langCode.toLowerCase().replace('_', '-'),
        value: value.startsWith('/') ? value : `/${value}`,
      }
    })
  }

  const pageId = seoConfig && seoConfig[url] && seoConfig[url]['id']
  const skipAlternateUrlsQuery = !brand || !pageId
  const { data: alternateUrlsMap } = useGetAlternateUrlQuery({ brand, pageId }, { skip: skipAlternateUrlsQuery })

  useEffect(() => {
    if (!!alternateUrlsMap) {
      dispatch(setAlternateHrefs(getAlternateUrls(alternateUrlsMap)))
    }
  }, [alternateUrlsMap])

  /* TODO: fix BFF parsing of TM symbol for urls */
  const currentSeoConfig =
    seoConfig &&
    (seoConfig?.[url] ??
      Object.values(seoConfig).find(
        entry => entry.identifier === url
      ) /* if no key matching url exists, check the identifier property */ ??
      seoConfig?.['NotFound'])

  if (!!currentSeoConfig) {
    if (currentSeoConfig?.redirect && currentSeoConfig?.redirect?.trim() !== '') {
      const url = currentSeoConfig.redirect.replace(currentLangCode.toLowerCase(), '')
      router.push(seoUrlConcat(currentLangCode, url))
    }

    return (
      <div className="page">
        <HeadComponent seoAltUrl={seoAltUrl} c={currentSeoConfig} host={host} />
        <SeoPageComponent page={currentSeoConfig.page} {...props} />
      </div>
    )
  } else {
    return null
  }
}

export default SEO
