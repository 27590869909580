import React, { useEffect } from 'react'
import { ReadonlyURLSearchParams, useSearchParams } from 'next/navigation'
import { Configure, InstantSearch, InstantSearchSSRProvider } from 'react-instantsearch-hooks-web'
// components
import { StyledPLPFilteredContent } from '@components/PlpFilter/PlpFilter.style'
import PlpFilterAlgolia from '@components/PlpFilter/PlpFilterAlgolia'
import { ProductGridLayoutAlgolia } from '@components/ProductGrid'
import VirtualSearchBox from '@components/VirtualSearchBox'
import { PAGINATION_CONFIGS } from '@configs/catalog'
import { ICategory } from '@features/category/query'
import { algoliaClient } from '@foundation/algolia/algoliaConfig'
// types
import { ICommerceHclPage } from '@typesApp/cms'
import { IAlgoliaHit } from '@typesApp/product'
import { IPage } from '@typesApp/seo'
// utils
import { useCustomerSegmentsUtil } from '@utils/Cookies'
import getRouting from '@utils/Plp/AlgoliaRouting'
import { PlpProvider } from '@utils/Plp/PlpContext'
import { IPlpState } from '@utils/Plp/PlpReducer'
import { isWunderkindEnabledForLocale } from '@components/Wunderkind/Wunderkind'
import { useRouter } from 'next/compat/router'
import { useNavigateForward } from '@hooks/useNavigateForward'

export interface PLP {
  page: IPage
  plpState: IPlpState
  categoryData: ICategory[] | null
  parentCatalogGroupID?: string[] | null
  ungroupedHits: IAlgoliaHit[]
  plpCommerce: ICommerceHclPage | undefined
}

const Plp: React.FC<PLP> = props => {
  const { categoryData, parentCatalogGroupID, plpState, plpCommerce } = props
  const router = useRouter()
  const { isForwardNavigation, targetUrl } = useNavigateForward()
  const searchParams: ReadonlyURLSearchParams & { query?: string } = useSearchParams()
  const { categoryId, categoryIdentifier, algoliafilterCategory, groupedIndexName } = plpState
  const { pageLimit } = PAGINATION_CONFIGS
  const customerSegment = useCustomerSegmentsUtil()[0]
  const locale = plpState?.locale ?? ''

  useEffect(() => {
    if (isForwardNavigation && targetUrl) {
      router?.replace(targetUrl, undefined, { scroll: false })
    }
  }, [isForwardNavigation, router, targetUrl])

  useEffect(() => {
    if (window?.wunderkind && isWunderkindEnabledForLocale(locale)) {
      window.wunderkind.category = {
        categoryIds: !!categoryData ? categoryData.map(cat => cat.id) : [],
        title: !!categoryData ? categoryData.at(-1)?.description ?? '' : '',
      }
    }
  }, [categoryData, locale])

  return (
    <PlpProvider initialState={plpState}>
      <InstantSearchSSRProvider {...plpState.serverState}>
        <InstantSearch
          searchClient={algoliaClient}
          indexName={groupedIndexName}
          routing={getRouting(groupedIndexName, plpState.serverUrl, customerSegment, plpState.locale)}
        >
          <Configure facets={['*']} filters={algoliafilterCategory} hitsPerPage={pageLimit} clickAnalytics={true} />
          <VirtualSearchBox algoliaQueryParam={searchParams?.query ?? ''} />
          <PlpFilterAlgolia searchClient={algoliaClient} />
          <StyledPLPFilteredContent id={categoryIdentifier}>
            <ProductGridLayoutAlgolia
              cid={`category-products-${categoryIdentifier}`}
              categoryId={categoryId}
              searchClient={algoliaClient}
              categoryFilter={algoliafilterCategory}
              categoryData={categoryData}
              parentCatalogGroupID={parentCatalogGroupID}
              plpCommerce={plpCommerce}
            />
          </StyledPLPFilteredContent>
        </InstantSearch>
      </InstantSearchSSRProvider>
    </PlpProvider>
  )
}

export default Plp
